import React,{ useEffect,useState } from 'react';
import './App.css';
import styled from "styled-components";
import axios from "axios";
import { init, dispose } from 'klinecharts'
import defaultSettings from "./defaultSettings";
const { apiBaseUrl } = defaultSettings;
const MenuContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin-top: 10px;
font-size: 12px;
color: #606060;
width: 100%;
`

const KLine = styled.div`
display: flex;
flex: 1;
width: 100%;

`

const ChartContainer = styled.div`
display: flex;
flex-direction: column;
border-radius: 2px;
box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
background-color: #131B24;
width: 100%;
height: 400px;
/* padding: 16px 6px 16px 16px; */
margin-top:5px;
/* overflow: scroll; */
`

function App() {
  const [timeScale, setTimeScale] = useState("1m");
  const [, serTimeline] = useState(false);
  const [precision,setPrecision] = useState(0)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let kLineChart = init('tooltip-k-line')
    if(kLineChart){
      kLineChart.setPriceVolumePrecision(precision,precision)
      kLineChart.createTechnicalIndicator('MA', false, { id: 'candle_pane' })
      kLineChart.setStyleOptions({candle:{tooltip:{labels:['T：', 'O：', 'C：', 'H：', 'L：', 'V：'],text:{size:10}},priceMark:{text:{size:6}},technicalIndicator:{tooltip:{text:{size:6}},lastValueMark:{text:{size:6}}}}})
      kLineChart.createTechnicalIndicator('VOL', false, { height: 40 })
    }
    axios
          .get(
            `${apiBaseUrl}/market/kline?symbol=${params.get('trade')?.split("USDT")[0]}-USDT&intv=${timeScale}`
          )
          .then((response) => {
            console.log(response)
            var data = response.data.data.map((jsonStr: string) => {
              let kline = JSON.parse(jsonStr);
              if(parseFloat(kline[4]) > 1 && parseFloat(kline[4]) < 50 ){
                setPrecision(3)
              }
              if(parseFloat(kline[4]) > 50 ){
                setPrecision(2)
              }
              if(parseFloat(kline[4]) < 1 && parseFloat(kline[4]) > 0.1){
                setPrecision(4)
              }
              if(parseFloat(kline[4]) < 0.1 && parseFloat(kline[4]) > 0.006){
                setPrecision(5)
              }
              if(parseFloat(kline[4]) < 0.006 && parseFloat(kline[4]) > 0){
                setPrecision(6)
              }
              return {
                timestamp: kline[0]*1000,
                open: parseFloat(kline[1]),
                high: parseFloat(kline[2]),
                low: parseFloat(kline[3]),
                close: parseFloat(kline[4]),
                volume:kline[5]
              };
            });
            if(kLineChart){
              kLineChart.applyNewData(data)         
            }
          });
    const interval = setInterval(() => {
    axios
          .get(
            `${apiBaseUrl}/market/kline?symbol=${params.get('trade')?.split("USDT")[0]}-USDT&intv=${timeScale}`
          )
          .then((response) => {
            var data = response.data.data.map((jsonStr: string) => {
              let kline = JSON.parse(jsonStr);
              return {
                timestamp: kline[0]*1000,
                open: parseFloat(kline[1]),
                high: parseFloat(kline[2]),
                low: parseFloat(kline[3]),
                close: parseFloat(kline[4]),
                volume:kline[5]
              };
            });
            if(kLineChart){
              kLineChart.applyNewData(data)         
            }
          });
    },1000)
    
    return () => {
      clearInterval(interval)
      dispose('tooltip-k-line')
    }
  }, [timeScale,precision])

  return (
    <div style={{backgroundColor: "#131B24",paddingTop:10,height:"100vh",display:"flex",flexDirection:"column"}}>
    <div
      style={{
        width: "100%",
        // height: "35%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: 12,
        marginLeft:5
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            paddingRight: 24,
            color: timeScale === "1m" ? "#F4F5F6" :"#F4F5F6",
          }}
          onClick={() => {
            setTimeScale("1m");
            serTimeline(false);
          }}
        >
          1M
        </div>
        <div
          style={{
            paddingRight: 24,
            color: timeScale === "15m" ? "#F4F5F6" :"#F4F5F6",
          }}
          onClick={() => {
            setTimeScale("15m");
            serTimeline(false);
          }}
        >
          15M
        </div>
        <div
          style={{
            paddingRight: 24,
            color: timeScale === "1h" ? "#F4F5F6" :"#F4F5F6",
          }}
          onClick={() => {
            setTimeScale("1h");
            serTimeline(false);
          }}
        >
          1H
        </div>
        <div
          style={{
            paddingRight: 24,
            color: timeScale === "4h" ? "#F4F5F6" : "#F4F5F6",
          }}
          onClick={() => {
            setTimeScale("4h");
            serTimeline(false);
          }}
        >
          4H
        </div>
        <div
          style={{
            paddingRight: 24,
            color: timeScale === "1d" ? "#F4F5F6" : "#F4F5F6",
          }}
          onClick={() => {
            setTimeScale("1d");
            serTimeline(false);
          }}
        >
          1D
        </div>
        <div
          style={{
            height: "100%",
            paddingRight: 24,
            color:
              timeScale === "timeshare"
                ? "#000"
                : "#000",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => {
            serTimeline((v) => !v);
            setTimeScale("timeshare");
          }}
        >
          {/* <p style={{ paddingRight: 6 }}>更多</p> */}

        </div>
      </div>
      {/* <div>{timeScale}</div> */}
    </div>    
         
    <ChartContainer
    className="k-line-chart-container">
    {/* <ChartTitle
      className="k-line-chart-title">{title}</ChartTitle> */}
      <KLine id="tooltip-k-line" className="k-line-chart"/>
      <MenuContainer
        className="k-line-chart-menu-container">
          <div style={{height:15}}></div>
      </MenuContainer>
  </ChartContainer>
  </div>
  );
}

export default App;
